<template>
    <LayoutReconciliationTab pageType="offers"/>
</template>

<script>
import LayoutReconciliationTab from '../layouts/LayoutReconciliationTab.vue';
export default {
    name: 'PageReconciliationOffers',
    components: {LayoutReconciliationTab}
}
</script>